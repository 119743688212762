import type { ThemeOverride } from "@chakra-ui/react";
import { createMultiStyleConfigHelpers, extendTheme } from "@chakra-ui/react";
import { listAnatomy } from "@chakra-ui/anatomy";
import { mode } from "@chakra-ui/theme-tools";

const listHelpers = createMultiStyleConfigHelpers(listAnatomy.keys);

export const themeOverride: ThemeOverride = {
  textStyles: {
    product: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: "bold",
      letterSpacing: "widest",
      fontSize: "lg",
      textTransform: "uppercase",
      lineHeight: "none",
    },
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  semanticTokens: {
    colors: {
      clarity: "rgb(54, 191, 239)",
      "link-blue": "#066d91",
      "dark-blue": "#1d2d3c",
      "light-blue": "#54697c",
      "light-gray": "#B8B8BC",
      "light-cream": "#f3f0ed",
      primary: {
        default: "dark-blue",
        _dark: "light-cream",
      },
      secondary: {
        default: "light-blue",
        _dark: "light-gray",
      },
      background: {
        default: "light-cream",
        _dark: "dark-blue",
      },
      "primary-dark": {
        default: "light-cream",
        _dark: "dark-blue",
      },
      "background-dark": {
        default: "dark-blue",
        _dark: "light-cream",
      },
      "secondary-dark": {
        default: "light-gray",
        _dark: "light-blue",
      },
      "button-background": {
        default: "light-blue",
        _dark: "dark-blue",
      },
      "button-color": {
        default: "light-cream",
        _dark: "light-gray",
      },
    },
  },
  components: {
    Button: {
      variants: {
        solid: {
          backgroundColor: "button-background",
          color: "button-color",
          _hover: {
            backgroundColor: "button-background",
            color: "button-color",
          },
        },
      },
    },
    Text: {
      baseStyle: {
        color: "primary",
        fontSize: "lg",
      },
    },
    Link: {
      baseStyle: {
        color: "link-blue",
      },
    },
    Heading: {
      baseStyle: {
        color: "primary",
      },
      defaultProps: {
        variant: "h2",
      },
      variants: {
        h1: {
          as: "h1",
          fontSize: "4xl",
        },
        h2: {
          as: "h2",
          fontSize: "3xl",
        },
        h3: {
          as: "h2",
          fontSize: "2xl",
        },
      },
    },
    List: listHelpers.defineMultiStyleConfig({
      baseStyle: listHelpers.definePartsStyle((props) => ({
        container: {
          listStylePos: "outside",
          paddingLeft: 5,
        },
        item: {
          fontSize: "lg",
        },
      })),
    }),
  },
  styles: {
    global: () => ({
      body: {
        fontFamily: "'Poppins', sans-serif",
        backgroundColor: "background",
        color: "primary",
      },
    }),
  },
};

export const theme = extendTheme(themeOverride);

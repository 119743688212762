import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import type { RequestDocument, Variables } from "graphql-request";
import { request as graphqlRequest } from "graphql-request";

export interface RequestOptions {
  includeDrafts?: boolean;
}

export const request = <TDocument = any, TVariables extends Variables = any>(
  document: RequestDocument | TypedDocumentNode<TDocument, TVariables>,
  variables: TVariables,
  options: RequestOptions
) => {
  const endpoint = process.env.GRAPHQL_ENDPOINT;
  const token = process.env.GRAPHQL_TOKEN;

  if (!endpoint || !token) {
    throw new Error("No endpoint or token found in environment variables.");
  }

  return graphqlRequest<TDocument, Variables>(endpoint, document, variables, {
    Authorization: `Bearer ${token}`,
    "X-Exclude-Invalid": "true",
    ...(options.includeDrafts && {
      "X-Include-Drafts": "true",
    }),
  });
};

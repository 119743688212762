import {
  Box,
  Divider,
  Heading,
  Link as ChakraLink,
  ListItem,
  OrderedList,
  Stack,
  Text as ChakraText,
  UnorderedList,
  Code,
} from "@chakra-ui/react";
import {
  isBlockquote,
  isCode,
  isHeading,
  isLink,
  isList,
  isListItem,
  isParagraph,
  isRoot,
  isThematicBreak,
} from "datocms-structured-text-utils";
import { Fragment } from "react";
import { renderNodeRule } from "react-datocms";
import { Highlight, themes } from "prism-react-renderer";

export const nodeRules = [
  renderNodeRule(isParagraph, ({ children, key, ancestors }) => {
    if (isRoot(ancestors[0])) {
      return <ChakraText key={key}>{children}</ChakraText>;
    }

    return <Fragment key={key}>{children}</Fragment>;
  }),
  renderNodeRule(isHeading, ({ node, children, key }) => {
    return (
      <Heading variant={`h${node.level}`} key={key}>
        {children}
      </Heading>
    );
  }),
  renderNodeRule(isCode, ({ node, key }) => {
    return (
      <Highlight
        language={node.language || ""}
        code={node.code}
        theme={themes.okaidia}
        key={key}
      >
        {({ style, tokens, getLineProps, getTokenProps }) => (
          <Code
            padding={5}
            rounded="md"
            display="block"
            whiteSpace="pre"
            backgroundColor={style.backgroundColor}
            overflow="auto"
          >
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line })}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token })} />
                ))}
              </div>
            ))}
          </Code>
        )}
      </Highlight>
    );
  }),
  renderNodeRule(isLink, ({ node, key, children }) => {
    return (
      <ChakraLink key={key} href={node.url}>
        {children}
      </ChakraLink>
    );
  }),
  renderNodeRule(isList, ({ node, key, children }) => {
    if (node.style == "bulleted") {
      return <UnorderedList key={key}>{children}</UnorderedList>;
    }

    return <OrderedList key={key}>{children}</OrderedList>;
  }),
  renderNodeRule(isListItem, ({ key, children }) => {
    return <ListItem key={key}>{children}</ListItem>;
  }),
  renderNodeRule(isBlockquote, ({ node, key, children }) => {
    return (
      <Stack
        key={key}
        borderLeftWidth={2}
        borderColor="brand"
        padding={5}
        maxWidth={500}
      >
        <Box>{children}</Box>
        {node.attribution && (
          <ChakraText
            as="i"
            alignSelf="end"
          >{`- ${node.attribution}`}</ChakraText>
        )}
      </Stack>
    );
  }),
  renderNodeRule(isThematicBreak, ({ key }) => {
    return (
      <Box key={key} marginY={5}>
        <Divider />
      </Box>
    );
  }),
];
